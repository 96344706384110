import styled from 'styled-components'
import { useGlobalState } from '@contexts/global'
import { useTranslation } from 'next-i18next'
import { ProductType } from '@service/configuration.types'
import { GATrackingActions, GATrackingCategories } from '@util/enums'
import { Tab } from '../DesktopProductSwitcher'
import { device } from '@util/responsive'
import { useMediaQuery } from '@hooks/mediaQuery'
import { useProductSwitcher } from '../useProductSwitcher'
import dynamic from 'next/dynamic'
import { trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'
import {
    getListingFilterFromQuery,
    getPriceBoundsForProduct,
    useListingQuery,
} from '@components/modules/Car/useListingQuery'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { useCommon } from '@contexts/common'
import { useLocaleConfig } from '@contexts/config'
import { useSearch } from '@contexts/search'
import { SecondaryBanner } from '@components/global/icons/SecondaryBanner'

const Filter = dynamic(() => import('@components/modules/Car/Filter').then(({ Filter }) => Filter), {
    ssr: false,
})

export const MobileBookingQuickFilter = () => {
    const { t } = useTranslation()
    const { bookingState } = useGlobalState()
    const { onTabSelected } = useProductSwitcher()
    const isDesktop = useMediaQuery(device.laptop)
    const { changeProductTo } = useCommon()
    const { user, isEnabledNewBookingFlow, isCareem } = useAuth()
    const { query } = useRouter()
    const { citySettings } = useLocaleConfig()
    const { maxDailyPrice, minDailyPrice, carType, makeIds, modelIds, engineTypes, gearboxTypes } = query
    const listingParams = getListingFilterFromQuery(query, citySettings, isEnabledNewBookingFlow)
    const { minPrice, maxPrice } = getPriceBoundsForProduct(listingParams.productType, query, citySettings)

    const filterCount = useMemo(() => {
        let count = 0
        const validMinPrice = minDailyPrice ? minDailyPrice : minPrice
        const validMaxPrice = maxDailyPrice ? maxDailyPrice : maxPrice
        if (validMinPrice != minPrice || validMaxPrice != maxPrice) {
            count++
        }
        if (carType) count++
        if (makeIds && makeIds.length > 0) count++
        if (modelIds && modelIds.length > 0) count++
        if (engineTypes && engineTypes.length > 0) count++
        if (gearboxTypes && gearboxTypes.length > 0) count++
        return count
    }, [carType, engineTypes, gearboxTypes, makeIds, maxDailyPrice, minDailyPrice, modelIds, minPrice, maxPrice])
    const { filterResultsNumber } = useListingQuery()

    const selectedProductType = bookingState.productType
    const isDaily = selectedProductType === ProductType.DAILY

    useEffect(() => {
        changeProductTo(ProductType.MONTHLY)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])

    const { initializationByCitySettings } = useSearch()

    return (
        <>
            <MobileTimeFilterWrapper>
                <ProductSelectionTabsWrapper>
                    <ProductSelectionTab
                        active={!isDaily}
                        onClick={() => {
                            onTabSelected(ProductType.MONTHLY)
                            trackEvent({
                                action: GATrackingActions.H_MONTHLY_CLICK,
                                category: GATrackingCategories.HOMETAB,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                    >
                        <Tab>
                            <TabTitleMobile active={!isDaily}>{t('homePageTab.monthly', 'Monthly')}</TabTitleMobile>
                        </Tab>
                    </ProductSelectionTab>
                    <ProductSelectionTab
                        active={isDaily}
                        onClick={() => {
                            onTabSelected(ProductType.DAILY)
                            // Following check has been added to reset dates when user moved from Monthly to Daily selection
                            initializationByCitySettings(true, true)
                            trackEvent({
                                action: GATrackingActions.H_DAILY_CLICK,
                                category: GATrackingCategories.HOMETAB,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                    >
                        <Tab>
                            <TabTitleMobile active={isDaily}>{t('homePageTab.daily', 'Daily')}</TabTitleMobile>
                        </Tab>
                    </ProductSelectionTab>
                </ProductSelectionTabsWrapper>
            </MobileTimeFilterWrapper>
            {isCareem && (
                <SecondaryBannerConatiner>
                    <SecondaryBanner />
                </SecondaryBannerConatiner>
            )}
            <MobileFilterAndTextWrapper>
                {typeof filterCount === 'number' && filterCount > 0 ? (
                    <MobileAvailableCarsText>
                        {t('availableCarsCount', '{{ amount }} cars available', {
                            amount: filterResultsNumber,
                        })}
                    </MobileAvailableCarsText>
                ) : (
                    <MobileAvailableCarsText>{t('allAvailableCars', 'All available cars')}</MobileAvailableCarsText>
                )}
                <Filter filterCount={filterCount} />
            </MobileFilterAndTextWrapper>
        </>
    )
}

const MobileTimeFilterWrapper = styled.div`
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background: rgba(118, 118, 128, 0.12);
    border-radius: 50px;
`

const ProductSelectionTabsWrapper = styled.div`
    display: flex;
    z-index: 10;
    position: relative;
    border-radius: 16px 16px 16px 16px;
    padding: 4px;
`

const ProductSelectionTab = styled.div<{ active: boolean }>`
    display: flex;
    width: 50%;
    background: ${(props) => (props.active ? 'white' : '')};
    color: ${(props) => (props.active ? 'var(--primaryColor)' : 'white')};
    padding: 8px var(--padding);
    transition: all cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.2s;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: ${(props) => (props.active ? ' 0px 3px 1px 0px rgba(0, 0, 0, 0.04)' : '')};
    box-shadow: ${(props) => (props.active ? '0px 3px 8px 0px rgba(0, 0, 0, 0.12)' : '')};
`
const MobileFilterAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const MobileAvailableCarsText = styled.div`
    padding-top: 15px;
    font-weight: var(--weight-semiBold);
`
export const TabTitleMobile = styled.div<{ active: boolean }>`
    font-size: 15px;
    color: rgba(0, 0, 0, 1);

    @media ${device.tablet} {
        font-size: var(--size-20);
        color: black;
    }
`
const SecondaryBannerConatiner = styled.div`
    margin-right: -16px;
    margin-left: -16px;
`
